import { useMemo, useState } from "react"
import styled from "styled-components"

import { MenuItem } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"

import {
  IWifiCredentials,
  TSupportedWifiSecurity,
} from "src/components/Install/installTypes"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { MButtonLegacy as LoadingButton } from "src/ui/Button/MButtonLegacy"
import { spacing } from "src/ui/spacing"

const WIFI_WPA2 = "wpa2_aes"
const WIFI_OPEN = "open"

export function InstallDeviceForm({
  onInstall,
  loading,
}: {
  onInstall: (props: {
    home: THome
    wifiOffice: IWifiCredentials
    wifiHome?: IWifiCredentials
  }) => void
  loading?: boolean
}) {
  const { org } = useOrganization()
  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {},
  })
  const homes: THome[] = useMemo(
    () => fetchHomes.data?.homes || [],
    [fetchHomes.data]
  ) // TODO WEB-xxx: Handle home pagination

  const [deviceHome, setDeviceHome] = useState<THome | undefined>()

  const [officeSsid, setOfficeSsid] = useState("")
  const [officeWifiPassword, setOfficeWifiPassword] = useState("")
  const [officeWifiSecurity, setOfficeWifiSecurity] =
    useState<TSupportedWifiSecurity>(WIFI_WPA2)

  const [homeSameAsOffice, setHomeSameAsOffice] = useState(false)
  const [homeSsid, setHomeSsid] = useState("")
  const [homeWifiPassword, setHomeWifiPassword] = useState("")
  const [homeWifiSecurity, setHomeWifiSecurity] =
    useState<TSupportedWifiSecurity>(WIFI_WPA2)

  return (
    <CredInput>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="home">Home</InputLabel>
        <Select
          fullWidth
          value={deviceHome?.home_id ?? ""}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
          onChange={(e: any) =>
            setDeviceHome(
              homes.find((home) => home.home_id === e.target.value) || homes[0]
            )
          }
          label="Home"
          inputProps={{
            name: "home",
            id: "home",
          }}
          disabled={homes.length <= 1}
        >
          {homes.map((home) => (
            <MenuItem key={home.home_id} value={home.home_id}>
              {home.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Hack to prevent Chrome from saving password */}
      {/* <input type="password" style={{ display: "none" }} /> */}

      <TextField
        id="office-wifi-ssid"
        name="office-wifi-ssid"
        label="Office Wifi SSID"
        autoComplete="chrome-off"
        required
        value={officeSsid}
        onChange={(e) => {
          setOfficeSsid(e.target.value)
        }}
        fullWidth
      />

      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="wifi-security">Office Wifi Security</InputLabel>
        <Select
          native
          value={officeWifiSecurity}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
          onChange={(e: any) => setOfficeWifiSecurity(e.target.value)}
          label="Office Wifi Security"
          inputProps={{
            name: "office-wifi-security",
            id: "office-wifi-security",
          }}
        >
          <option value={WIFI_OPEN}>Open</option>
          <option value={WIFI_WPA2}>WPA2</option>
        </Select>
      </FormControl>

      <TextField
        type="password"
        hidden={officeWifiSecurity === WIFI_OPEN}
        id="office-wifi-pw"
        name="office-wifi-pw"
        label="Office Wifi password"
        autoComplete="wifi-password"
        required={officeWifiSecurity !== WIFI_OPEN}
        value={officeWifiPassword}
        onChange={(e) => {
          const pw = e.target.value
          setOfficeWifiPassword(pw)
        }}
        fullWidth
      />

      <SettingToggle
        title={`Separate home wifi network`}
        description={
          <div>
            If the Wifi credentials are different at the home where the device
            will be installed, you may enter them separately below.
          </div>
        }
        onSave={async () => {
          setHomeSameAsOffice((c) => !c)

          return { isSuccess: true }
        }}
        value={!homeSameAsOffice}
      />

      <div style={{ display: homeSameAsOffice ? "none" : "contents" }}>
        <TextField
          id="home-wifi-ssid"
          name="home-wifi-ssid"
          label="Home Wifi SSID"
          autoComplete="chrome-off"
          required
          value={!homeSameAsOffice ? homeSsid : officeSsid}
          onChange={(e) => {
            setHomeSsid(e.target.value)
          }}
          disabled={homeSameAsOffice}
          fullWidth
        />

        <FormControl fullWidth variant="outlined" disabled={homeSameAsOffice}>
          <InputLabel htmlFor="home-wifi-security">
            Home Wifi Security
          </InputLabel>
          <Select
            native
            value={!homeSameAsOffice ? homeWifiSecurity : officeWifiSecurity}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
            onChange={(e: any) => setHomeWifiSecurity(e.target.value)}
            label="Homw Wifi Security"
            inputProps={{
              name: "home-wifi-security",
              id: "home-wifi-security",
            }}
            disabled={homeSameAsOffice}
          >
            <option value={WIFI_OPEN}>Open</option>
            <option value={WIFI_WPA2}>WPA2</option>
          </Select>
        </FormControl>

        <TextField
          type="password"
          fullWidth
          hidden={homeWifiSecurity === WIFI_OPEN}
          id="home-wifi-pw"
          name="home-wifi-pw"
          label="Home Wifi password"
          autoComplete="wifi-password"
          required={officeWifiSecurity !== WIFI_OPEN}
          value={!homeSameAsOffice ? homeWifiPassword : officeWifiPassword}
          onChange={(e) => {
            const pw = e.target.value
            setHomeWifiPassword(pw)
          }}
          disabled={homeSameAsOffice}
        />
      </div>

      <UploadButton
        variant="contained"
        color="primary"
        onClick={() =>
          deviceHome &&
          onInstall({
            home: deviceHome,
            wifiOffice: {
              password: officeWifiPassword,
              ssid: officeSsid,
              security: officeWifiSecurity,
            },
            wifiHome: homeSameAsOffice
              ? undefined
              : {
                  password: homeWifiPassword,
                  ssid: homeSsid,
                  security: homeWifiSecurity,
                },
          })
        }
        disabled={
          !deviceHome ||
          !officeSsid ||
          !(officeWifiSecurity === WIFI_WPA2 && officeWifiPassword) ||
          (!homeSameAsOffice && !homeSsid) ||
          (!homeSameAsOffice &&
            !(homeWifiSecurity === WIFI_WPA2 && homeWifiPassword))
        }
        loading={loading}
      >
        {deviceHome ? `Setup device for ${deviceHome.name}` : "Setup device"}
      </UploadButton>
    </CredInput>
  )
}

const CredInput = styled.div`
  max-width: 70ch;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  & > * {
    flex-grow: 1;
  }
`

const UploadButton = styled(LoadingButton)`
  margin-top: ${spacing.XL4};
  flex: 1 1 100%;
`
